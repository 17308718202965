
import React from 'react';
import { Button, Stack } from '@mui/material';

const CustomButton = ({ color, className, onClick, onMouseEnter, onMouseLeave, sx, icon, width, height, label, backgroundColor, hoverBackgroundColor,
    activeBackgroundColor, disableRipple, isButtonClicked, isButtonHovered,font,fontWeight, ...rest
}) => {
  return (
    <Button
      variant='contained'
      color={color}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disableRipple={disableRipple}
      sx={{
        width: width,
          height: height,
        backgroundColor: isButtonClicked || isButtonHovered ? hoverBackgroundColor : backgroundColor,
        '&:hover': {
          backgroundColor: hoverBackgroundColor,
        },
        '&:active': {
          backgroundColor: activeBackgroundColor,
        },
        color: color,
        fontFamily: font,
        fontWeight: fontWeight,
        whiteSpace: 'nowrap',
        flexGrow: 1,
        minWidth: 'unset',
        textTransform: 'none',
        display: 'flex',
        justifyContent :'center', 
        alignItems :'center',
        ...sx, 
      }}
      {...rest}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {icon && <img src={icon} alt={label} width="16" height="16" className="icon" />}
        <span>{label}</span>
      </Stack>
    </Button>
  );
};

export default CustomButton;
