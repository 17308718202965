export function ReportIcon({ color }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="3" width="20" height="19" rx="2" fill={color} />
            <path d="M7 11L8.125 12L10 10" stroke="#ECECEC" strokeWidth="2" strokeLinecap="round" />
            <path d="M7 16L8.125 17L10 15" stroke="#ECECEC" strokeWidth="2" strokeLinecap="round" />
            <path d="M14 11L17 11" stroke="#ECECEC" strokeWidth="2" strokeLinecap="round" />
            <path d="M14 16L17 16" stroke="#ECECEC" strokeWidth="2" strokeLinecap="round" />
            <rect x="7" y="1" width="10" height="6" rx="2" fill={color} />
        </svg>
    )
}
