import React, { useState, useEffect } from 'react';
import { fetchHarvestRequests } from "../api";

export function HarvestRequestIcon({ setHarvestRequest, harvestRequest, color }) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchHarvestRequests();
                const pendingRequests = data.filter(request => request.status === 'Pending');
                setHarvestRequest(pendingRequests.length);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching harvest requests:', error);
            }
        };

        fetchData();
    }, [setHarvestRequest]);

    if (isLoading) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 28 22" width="30">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" fill={color} />
            </svg>
        );
    }

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 28 22" width="30">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" fill={color} />
            </svg>
            {harvestRequest > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 12,
                    }}
                >
                    {harvestRequest}
                </div>
            )}
        </div>
    );
}