
import React from 'react';
import { Stack, Button } from '@mui/material';

const CustomButtonStack = ({ onButtonClick, onCancelClick, width, height, sendLabel, cancelLabel, saveButtonRef, isDisabled, fontWeight, ...rest }) => {
  return (
    //Render a Stack buttons for reusing //
    <Stack direction="row" className="popup-buttons" {...rest}>
  
      <Button
        variant="contained"
        onClick={onCancelClick}
        sx={{
          width: width,
          height: height,
          fontWeight: fontWeight,
          backgroundColor: "#B5B5B5",
          textTransform: "none",
          '&:hover': {
            backgroundColor: "#B5B5B5"
          }
        }}
      >
        {cancelLabel}
      </Button>

      <Button
        variant="contained"
        onClick={onButtonClick}
        ref={saveButtonRef} disabled={isDisabled}
        sx={{
            display: 'flex',
          width: width,
          height: height,
          fontWeight: fontWeight,
          backgroundColor: "#110202",
          color: "white",
          textTransform: "none",
          '&:hover': {
            backgroundColor: "#110202"
          }
        }}
      >
        {sendLabel}
      </Button>
    </Stack>
  );
};


export default CustomButtonStack ; 