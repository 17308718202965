import React, { useState } from 'react';
import Card from "../Reusablecomponents/Card";
import CustomButton from '../Reusablecomponents/Custombutton';
import './HarvestRequest.css';
import HarvestRequestPopup from "./HarvestRequestPopup";
import HarvestRequestTable from './HarvestRequestTable';


function HarvestRequest(props) {
    const [openPopup, setOpenPopup] = useState(false);
    const [reloadTable, setReloadTable] = useState(false);
    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };
    const handleRequestSent = () => {
        setReloadTable(!reloadTable);
    };

    return (
        <div className="center-container">
            <Card className="chart-container" height={'130%'}>
                <div className="row">
                    <div className="chart-title">
                        Harvest Request
                    </div>

                    <div className="button-container">
                        <CustomButton
                            label="New Harvest Request"
                            onClick={handleOpenPopup}
                            className="request-button"
                            backgroundColor="#6B6B6B"
                            hoverBackgroundColor="#05668D"
                            activeBackgroundColor="#05668D"
                        />
                    </div>
                </div>
                <HarvestRequestTable setHarvestRequest={props.setHarvestRequest} reloadTable={reloadTable} handleRequestSent={handleRequestSent} disabled={openPopup} />
            </Card>
            <HarvestRequestPopup setHarvestRequest={props.setHarvestRequest} open={openPopup} onClose={handleClosePopup} onRequestSent={handleRequestSent} />
        </div>
    );
}


export default HarvestRequest;