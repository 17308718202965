import React from 'react';
import { TextField, Typography } from '@mui/material';

const CustomTextField = ({ label, value, onChange, disabled, variant, onClickAll }) => {
    const words = label.split(' ');
    return (
        <div>
            <Typography variant="subtitle2">
                {words.map((word, index) => (
                    <span key={index} style={{ fontWeight: index === 0 ? 'bold' : 'normal' }}>
                        {word}
                        {index !== words.length - 1 && ' '}
                    </span>
                ))}
            </Typography>
            <TextField
                autoFocus
                margin="dense"
                type="number"
                value={value}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                variant={variant}
                size="small"
                sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#05668D',
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23) !important',
                    },
                    '& label': {
                        fontSize: '0.875rem',
                        color: '#05668D',
                        marginBottom: '0.15rem',
                    },
                    '& label.Mui-focused': {
                        color: '#05668D',
                    },
                    '& input': {
                        height: '0.75rem',
                    },
                    '& .Mui-disabled': {
                        height: '1.75rem',
                    },
                }}
            />
        </div>
    );
};

export default CustomTextField;
