import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography, IconButton, TextField, Divider, Menu, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import harvestLogo from '../Harvest_Logo.png';
import DeleteIcon from '@mui/icons-material/Delete';
import './ImageDetail.css';
import { format } from 'date-fns';
 
const ImageDetail = ({ enlargedImage, handleBackToGallery, currentIndex, selectedWeekThumbnails, handleNavigation, info, onCommentUpdate }) => {
  const [comment, setComment] = useState('');
  const [submittedComment, setSubmittedComment] = useState(info.comment || '');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [timestamp, setTimestamp] = useState(null);
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setComment(e.target.value);
  };
  const handleCancel = () => {
    setComment('');
    setSubmittedComment('');
    setIsClicked(false);
 
  };
 
  const handleSend = () => {
    setSubmittedComment(comment);
    setComment('');
    setTimestamp(new Date());
    onCommentUpdate(comment);
    setIsClicked(false);
  };
 
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };
  const handleTextFieldClick = (event) => {
    setIsClicked(true);
  };
 
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
 
  const handleDelete = () => {
    setSubmittedComment('');
    setTimestamp(new Date());
    handleCloseMenu();
    onCommentUpdate(null);
    handleSend()
  };
 
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: isClicked ? '#5668D' : '',
            },
          },
        },
      },
    },
  });
 
  return (
    <Box display="flex" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="1300" bgcolor="#717171" style={{ paddingTop: '55px', paddingBottom: '45px' }}>
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" marginRight="300px">
        <IconButton sx={{ position: 'absolute', left: 50, backgroundColor: 'rgba(255, 255, 255, 0.8)' }} onClick={() => handleNavigation(-1)}>
          <ArrowBackIosIcon />
        </IconButton>
        <img src={enlargedImage} alt="Enlarged" style={{ height: 'calc(100vh - 40px)', maxWidth: 'calc(100vw - 320px)', objectFit: 'contain' }} />
        <IconButton sx={{ position: 'absolute', right: 350, backgroundColor: 'rgba(255, 255, 255, 0.8)' }} onClick={() => handleNavigation(1)}>
          <ArrowForwardIosIcon />
        </IconButton>
 
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <IconButton onClick={handleBackToGallery} sx={{
            position: 'absolute',
            top: 10,
            right: 350,
            padding: '5px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: background color for better visibility
          }}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box width="330px" height="100%" display="flex" flexDirection="column" p={2} bgcolor="#F9F9F9" boxShadow={3} position="fixed" top="0" right="0">
        <Box flex="1" overflow="auto">
          <img src={harvestLogo} alt="Harvest_Logo" className="harvestLogo" style={{ height: '30px', }} />
          <Typography variant="body2" sx={{ width: '300px', wordWrap: 'break-word', }}> {info.location}, {info.created.split(',')[1].trim()}</Typography>
          <Box mt={2}>
            <Typography className="info-title">
              Info
            </Typography>
            <Box className="info-grid">
              <Typography className="info-label">
                Site:
              </Typography>
              <Typography className="info-value">
                {info.site}
              </Typography>
 
 
              <Typography className="info-label">
                Greenhouse:
              </Typography>
              <Typography className="info-value">
                {info.greenhouse}
              </Typography>
 
 
              <Typography className="info-label">
                Compartment:
              </Typography>
              <Typography className="info-value">
                {info.compartment}
              </Typography>
 
              <Typography className="info-label">
                Fruit:
              </Typography>
              <Typography className="info-value">
                {info.fruit}
              </Typography>
 
 
              <Typography className="info-label">
                Location:
              </Typography>
              <Typography className="info-value">
                {info.location}
              </Typography>
 
 
              <Typography className="info-label">
                Created:
              </Typography>
              <Typography className="info-value">
                {info.created}
              </Typography>
            </Box>
            <Divider className="info-divider" />
          </Box>
          <ThemeProvider theme={theme}>
            <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
 
              <Box display="flex" justifyContent="space-between" width="100%" mb={1}>
                <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px', marginTop: '10px' }}>
                  Note
                </Typography>
                {submittedComment && (
                  <Typography variant="body2" style={{ fontSize: '12px', color: '#717171', marginTop: '10px' }}>
                    {timestamp && format(timestamp, 'd MMM yyyy, HH:mm')}
                  </Typography>
                )}
              </Box>
              <TextField
                placeholder="Add note"
                variant="outlined"
                fullWidth
                multiline
                minRows={1}
                maxRows={Infinity}
                value={comment || submittedComment}
                onChange={handleInputChange}
                onClick={handleTextFieldClick}
                onKeyPress={handleKeyPress}
                InputProps={{
                  style: { backgroundColor: '#ffffff', borderColor: isClicked ? '#05668D' : '' }
                }}
              />
              {isClicked && (
                <Box mt={1} display="flex" justifyContent="flex-end" width="100%">
                  <IconButton onClick={handleCancel}>
                    <ClearIcon />
                  </IconButton>
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={handleSend}>
                    <SendIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </ThemeProvider>
 
        </Box>
      </Box>
    </Box>
  );
};
 
export default ImageDetail;