import React, { useState, useEffect } from 'react';
import { Divider, Box, Typography, TextField, Button, Stack, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Dropdown from '../Reusablecomponents/Dropdown';
import ButtonStack from '../Reusablecomponents/CustomButtonStack';
import { fetchAllCrops, createHarvestRequest, fetchUserDetails, fetchHarvestRequests } from "../api";
import CustomDateField from '../Reusablecomponents/CustomDateField';
import './HarvestRequestPopup.css';

function HarvestRequestPopup({ setHarvestRequest, open, onClose, onRequestSent, }) {
    const [plan, setPlan] = useState('Regular');
    const [crops, setCrops] = useState([]);
    const [showColon, setShowColon] = useState(false);
    const [amount, setAmount] = useState('');
    const [selectedCrop, setSelectedCrop] = useState('');
    const [userData, setUserData] = useState(null);
    const [harvestDate, setHarvestDate] = useState(new Date());
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCrops = async () => {
            try {
                const allCrops = await fetchAllCrops();
                setCrops(allCrops);
                if (allCrops.length > 0) {
                    setSelectedCrop(allCrops[0].cultivar);
                }
            } catch (error) {
                console.error('Error fetching crops:', error);
            }
        };

        fetchCrops();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await fetchUserDetails();
                setUserData(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUser();
    }, []);

    const handlePlanChange = (event) => {
        setPlan(event.target.value);
    };

    const handleAmountChange = (event) => {
        const value = event.target.value;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setAmount(value);
            if (isPositiveNumber(value)) {
                setError('');
            } else {
                setError(getErrorMessage(value));
            }
        }
    };

    const isPositiveNumber = (value) => {
        const number = Number(value);
        return number > 0 && Number.isInteger(number);
    };

    const getErrorMessage = (value) => {
        if (value <= '0') {
            return 'Please add amount';
        }
        return 'Only positive numbers are allowed';
    };
    const handleCropChange = (event) => {
        setSelectedCrop(event.target.value);
    };
    const handleHarvestDateChange = (date) => {
        setHarvestDate(date);
    }

    const handleClick = async () => {
        if (Number(amount) <= 0) {
            setError('Please add amount');
            return;
        }


        try {
            const userData = await fetchUserDetails();
            const [cultivar, name] = selectedCrop.split(' ');
            const requestData = {
                status: "Pending",
                from_user: userData,
                crop: {
                    id: crops.find(crop => crop.cultivar === selectedCrop).id,
                    name: crops.find(crop => crop.cultivar === selectedCrop).name,
                    cultivar: selectedCrop
                },
                amount: Number(amount),
                harvest_date: harvestDate.toISOString(),
                type: plan
            };

            const response = await createHarvestRequest(requestData);
            if (typeof response !== 'number') {
                alert('Failed to send harvest request. Please try again.');
            }
            else {
                onRequestSent();
                const data = await fetchHarvestRequests();
                const pendingRequests = data.filter(request => request.status === 'Pending');
                setHarvestRequest(pendingRequests.length)
            }
        } catch (error) {
            console.error('Error sending harvest request:', error);
            alert('An error occurred while sending the harvest request. Please try again later.');
        }
    }

    return open ? (
        <div className="popup-container">
            <div className="popup-header">
                <Typography variant="h6">
                    New Harvest Request
                </Typography>
            </div>
            <div className="popover-body">
                <Stack spacing={2} p={2} className="popover-body">
                    <RadioGroup
                        value={plan}
                        onChange={handlePlanChange}
                        className="radio-group"
                        row
                        sx={{ justifyContent: 'space-between' }}
                    >
                        <FormControlLabel
                            value="Regular"
                            control={
                                <Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            display: 'none',
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'inline-block',
                                            width: 15,
                                            height: 15,
                                            borderRadius: '50%',
                                            backgroundColor: 'transparent',
                                            border: '2px solid #05668D',
                                        },
                                        '&.Mui-checked:before': {
                                            backgroundColor: '#05668D',
                                            borderColor: '#05668D',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Regular plan
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value="Non Regular"
                            control={
                                <Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            display: 'none',
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'inline-block',
                                            width: 15,
                                            height: 15,
                                            borderRadius: '50%',
                                            backgroundColor: 'transparent',
                                            border: '2px solid #05668D',
                                        },
                                        '&.Mui-checked:before': {
                                            backgroundColor: '#05668D',
                                            borderColor: '#05668D',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Non regular plan
                                </Typography>
                            }
                        />
                    </RadioGroup>

                    <div className="dropdown-wrapper">
                        <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '7rem' }}>Requested crop:</Typography>
                        <Dropdown
                            values={crops.map(crop => crop.cultivar)}
                            selectedValue={selectedCrop}
                            handleChange={handleCropChange}
                            showColon={showColon}
                            className="custom-dropdown"
                        />
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="amount-row">
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Amount (kg):</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                sx={{ width: '153px', marginRight: '8px' }}
                                variant="outlined"
                                onChange={handleAmountChange}
                                value={amount}
                                error={!!error}
                                size="small"
                                helperText={error}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                            <Typography variant="body1" style={{ fontWeight: '400' }}>kg</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Harvest date:</Typography>
                        <CustomDateField value={harvestDate} onChange={handleHarvestDateChange} />
                    </Box>
                    <Divider sx={{ backgroundColor: '#B5B5B5' }} />
                    <ButtonStack onButtonClick={() => {
                        handleClick();
                        onClose();
                    }} cancelLabel="Cancel"
                        sendLabel="Send"
                        height="42px"
                        width="140px"
                        fontWeight="bold"
                        isDisabled={!isPositiveNumber(amount)}
                        onCancelClick={onClose} />
                </Stack>
            </div>
        </div>
    ) : null;
}

export default HarvestRequestPopup;