import React, { useState, useEffect } from 'react';
import Card from '../Reusablecomponents/Card';
import { fetchHarvestRequests, updateHarvestRequest, fetchUserDetails } from "../api";
import CustomButton from '../Reusablecomponents/Custombutton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import './HarvestRequestTable.css'
import { formatRequestedDate } from '../DateFormatter';

const columns = [
    { field: 'request_id', headerName: 'Requested ID', flex: 1 },
    { field: 'crop', headerName: 'Requested crop', flex: 1 },
    { field: 'amount', headerName: 'Amount (kg)', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'harvest_date', headerName: 'Harvest date', flex: 1 },
    { field: 'created_at', headerName: 'Requested date', flex: 1 },
    { field: 'from_user.first_name', headerName: 'Sent by', flex: 1 },
    { field: 'to_user', headerName: 'Responded by', flex: 1 },
];

const HarvestRequestTable = ({ handleRequestSent, setHarvestRequest, reloadTable, disabled }) => {
    const [harvestRequests, setHarvestRequests] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [comment, setComment] = useState('');
    const [userData, setUserData] = useState(null);
    const [interactedRow, setInteractedRow] = useState(null);
    const [respondedUsers, setRespondedUsers] = useState({});

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };
    const fetchRequests = async () => {
        try {
            const data = await fetchHarvestRequests();
            const pendingRequests = data.filter(request => request.status === 'Pending');
            setHarvestRequest(pendingRequests.length)
        } catch (error) {
            console.error('Error fetching harvest requests:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchHarvestRequests();
                setHarvestRequests(data);
                setSortField('request_id');
                setSortOrder('desc');
            } catch (error) {
                console.error('Error fetching harvest requests:', error);
            }
        };

        fetchData();

    }, [reloadTable]);

    const handleAccept = async (index) => {
        try {
            const updatedHarvestRequests = [...harvestRequests];
            const requestId = sortedRows[index].request_id;
            const { id, ...payloadWithoutId } = sortedRows[index];
            const updatedData = {
                ...payloadWithoutId,
                status: 'Accepted',
                to_user: userData,
                comment: comment
            };
            await updateHarvestRequest(requestId, updatedData);
            const data = await fetchHarvestRequests();
            const pendingRequests = data.filter(request => request.status === 'Pending');
            setHarvestRequest(pendingRequests.length)
            setExpandedRow(null);
            await fetchRequests();
            setComment('');
            handleRequestSent();
        } catch (error) {
            console.error('Error accepting harvest request:', error);
            alert('Failed to accept harvest request. Please try again.');
        }
    };

    const handleDecline = async (index) => {
        try {
            const updatedHarvestRequests = [...harvestRequests];
            const requestId = sortedRows[index].request_id;
            const { id, ...payloadWithoutId } = sortedRows[index];
            const updatedData = {
                ...payloadWithoutId,
                status: 'Declined',
                to_user: userData,
                comment: comment
            };
            await updateHarvestRequest(requestId, updatedData);
            setExpandedRow(null);
            await fetchRequests();
            const data = await fetchHarvestRequests();
            const pendingRequests = data.filter(request => request.status === 'Pending');
            setHarvestRequest(pendingRequests.length)
            setComment('');
            handleRequestSent();
        } catch (error) {
            console.error('Error declining harvest request:', error);
            alert('Failed to decline harvest request. Please try again.');
        }
    };
    const handleCancel = () => {
        setExpandedRow(null);
    };
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await fetchUserDetails();
                setUserData(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUser();
    }, []);


    const getUserFullName = (row, index) => {
        if (row.status !== 'Pending' && respondedUsers[index]) {
            return respondedUsers[index];
        }
        return row.status !== 'Pending' ? `${row.to_user.first_name} ${row.to_user.last_name}` : '';
    };


    const handleRowClick = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const sortedRows = [...harvestRequests].sort((a, b) => {
        if (sortField) {
            let valA = a[sortField];
            let valB = b[sortField];
            if (sortField === 'harvest_date' || sortField === 'created_at') {
                valA = new Date(valA).getTime();
                valB = new Date(valB).getTime();
            }
            if (valA < valB) return sortOrder === 'asc' ? -1 : 1;
            if (valA > valB) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        }
        return 0;
    });


    return (
        <div style={{ width: '100%' }} className={`harvest-table ${disabled ? 'disabled' : ''}`}>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.field} onClick={() => handleSort(column.field)}>
                                {column.headerName} {sortField === column.field && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>

                    {sortedRows.map((row, index) => (
                        <React.Fragment key={index}>
                            <tr className={row.status === 'Pending' ? 'bold-row' : ''}
                                onClick={() => handleRowClick(index)}>
                                {columns.map((column) => (
                                    <td key={column.field}>
                                        {column.field === 'harvest_date' ? formatRequestedDate(row[column.field], false, true) :
                                            column.field === 'created_at' ? formatRequestedDate(row[column.field], false, false) :
                                                column.field === 'to_user' ? getUserFullName(row, index) :
                                                    column.field === 'crop' ? row.crop.cultivar :
                                                        column.field === 'from_user.first_name' ? `${row.from_user.first_name} ${row.from_user.last_name}` :
                                                            column.field === 'to_user.first_name' ? `${row.to_user.first_name} ${row.to_user.last_name}` :
                                                                column.field === 'amount' ? row.amount.toLocaleString() :
                                                                    column.field === 'status' ? (
                                                                        <span className={`status status-${row.status.toLowerCase()}`}>
                                                                            {row.status}
                                                                        </span>
                                                                    ) : row[column.field]
                                        }
                                    </td>
                                ))}
                            </tr>
                            {expandedRow === index && (
                                < tr >
                                    <td colSpan={columns.length} >
                                        <div className="expanded-row">
                                            <div className="expanded-content" style={{ borderColor: row.status === 'Pending' ? '#FFA737' : row.status === 'Accepted' ? '#02C39A' : '#D34B36' }}>
                                                <div className="expanded-status">
                                                    <span className={`status status-${row.status.toLowerCase()}`}>
                                                        {row.status}
                                                    </span>
                                                    <span className={'type'}>{row.type.charAt(0).toUpperCase() + row.type.slice(1).toLowerCase()}{' plan'}</span>
                                                </div>

                                                <div>
                                                    <span>Requested crop:</span>
                                                    <span>{row.crop.cultivar}</span>
                                                </div>
                                                <div>
                                                    <span>Amount (kg):</span>
                                                    <span>{row.amount.toLocaleString()}</span>
                                                </div>
                                                <div>
                                                    <span>Harvest date:</span>
                                                    <span>{formatRequestedDate(row.harvest_date, false, true, true, true)}</span>
                                                </div>
                                                <div>
                                                    <span>Requested date:</span>
                                                    <span>{formatRequestedDate(row.created_at, true, true, true, true)}</span>
                                                </div>
                                                <div>
                                                    <span>Sent by:</span>
                                                    <span>{row.from_user.first_name} {row.from_user.last_name}</span>
                                                </div>
                                                {row.to_user && (
                                                    <div>
                                                        <span>Responded by:</span>
                                                        <span>{row.to_user.first_name} {row.to_user.last_name}</span>
                                                    </div>
                                                )}
                                                {row.status === 'Pending' ? (
                                                    <React.Fragment>
                                                        <div className="divider"></div>
                                                        <div className="comment-section">
                                                            <span>Comment (optional):</span>
                                                            <span>
                                                                <TextField
                                                                    type="text"
                                                                    placeholder="Add a comment"
                                                                    value={comment}
                                                                    onChange={handleCommentChange}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    fullWidth
                                                                    multiline
                                                                />
                                                            </span>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <div>
                                                        <span>Comment:</span>
                                                        <span>{row.comment}</span>
                                                    </div>
                                                )}
                                                <div>
                                                    {row.status === 'Pending' && (
                                                        <div className='button-stack' >
                                                            <CustomButton

                                                                sx={{ color: "#f5f5f5" }}
                                                                label="Accept"
                                                                width='33%'
                                                                height='45px'
                                                                fontWeight="bold"
                                                                backgroundColor="#02C39A"
                                                                hoverBackgroundColor="#02C39A"
                                                                onClick={() => handleAccept(index)}

                                                            />
                                                            <CustomButton

                                                                sx={{ color: "#f5f5f5" }}
                                                                label="Decline"
                                                                width='33%'
                                                                fontWeight="bold"
                                                                height='45px'
                                                                backgroundColor="#D34B36"
                                                                hoverBackgroundColor="#D34B36"

                                                                onClick={() => handleDecline(index)}
                                                            />

                                                            <CustomButton

                                                                sx={{ color: "#f5f5f5", }}
                                                                label="Cancel"
                                                                width='33%'
                                                                fontWeight="bold"
                                                                height='45px'
                                                                backgroundColor="#B5B5B5"
                                                                hoverBackgroundColor="#B5B5B5"
                                                                onClick={handleCancel}
                                                            />
                                                        </div>

                                                    )}
                                                </div>
                                                <span className="request-number">Request no: {row.request_id}</span>
                                            </div>

                                        </div>


                                    </td>
                                </tr>
                            )}

                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div >
    );
}
export default HarvestRequestTable;