import React, { useState, useEffect } from 'react';
import { fetchHarvestRequests } from "../api";

export function NotificationIcon({ setHarvestRequest, harvestRequest }) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchHarvestRequests();
                const pendingRequests = data.filter(request => request.status === 'Pending');
                setHarvestRequest(pendingRequests.length);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching harvest requests:', error);
            }
        };

        fetchData();
    }, [setHarvestRequest]);

    if (isLoading) {
        return (
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M24 39.9746C25.6454 39.9746 27 38.62 27 36.9746H29C29 39.7246 26.75 41.9746 24 41.9746C22.6333 41.9746 21.3667 41.4079 20.4667 40.5079C19.5667 39.6079 19 38.3413 19 36.9746H21C21 37.788 21.3395 38.5523 21.8809 39.0937C22.4223 39.6351 23.1866 39.9746 24 39.9746Z" fill="#110202" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.6016 28.5664L12.6015 28.5664L10.308 31.9625C10.3079 31.9625 10.3079 31.9626 10.3078 31.9627C10.3077 31.9628 10.3077 31.9629 10.3076 31.963C9.92931 32.5238 9.96748 32.9263 10.0709 33.1752C10.1878 33.4568 10.5372 33.8835 11.379 34.1383C19.6026 36.5871 28.4798 36.5867 36.7033 34.1373L36.7088 34.1356C37.3619 33.9432 37.7424 33.5365 37.9024 33.1503C38.0565 32.7781 38.0398 32.3628 37.7698 31.9611C37.7696 31.9608 37.7694 31.9606 37.7693 31.9603L35.4772 28.5664L35.4662 28.5501L35.4555 28.5336C35.0263 27.8704 34.6797 27.0424 34.4401 26.2635C34.2021 25.4899 34.0175 24.5959 34.0175 23.7837V18.6444C34.0175 14.0904 29.7494 9.97461 24.0493 9.97461C18.3293 9.97461 14.0811 14.0726 14.0811 18.6444V23.7837C14.0811 24.5966 13.8963 25.4919 13.6541 26.27C13.4106 27.052 13.0552 27.888 12.6067 28.5588L12.6016 28.5664ZM39.4285 30.8436C40.745 32.7997 39.6878 35.3427 37.2742 36.054C28.677 38.6148 19.4017 38.6148 10.8045 36.054C8.21137 35.2716 7.23397 32.942 8.65021 30.8436L10.9441 27.447C11.5625 26.5223 12.0811 24.8685 12.0811 23.7837V18.6444C12.0811 12.7582 17.4469 7.97461 24.0493 7.97461C30.6318 7.97461 36.0175 12.776 36.0175 18.6444V23.7837C36.0175 24.8685 36.5362 26.5223 37.1346 27.447L39.4285 30.8436Z" fill="#110202" />
            </svg>
        );
    }

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M24 39.9746C25.6454 39.9746 27 38.62 27 36.9746H29C29 39.7246 26.75 41.9746 24 41.9746C22.6333 41.9746 21.3667 41.4079 20.4667 40.5079C19.5667 39.6079 19 38.3413 19 36.9746H21C21 37.788 21.3395 38.5523 21.8809 39.0937C22.4223 39.6351 23.1866 39.9746 24 39.9746Z" fill="#110202" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.6016 28.5664L12.6015 28.5664L10.308 31.9625C10.3079 31.9625 10.3079 31.9626 10.3078 31.9627C10.3077 31.9628 10.3077 31.9629 10.3076 31.963C9.92931 32.5238 9.96748 32.9263 10.0709 33.1752C10.1878 33.4568 10.5372 33.8835 11.379 34.1383C19.6026 36.5871 28.4798 36.5867 36.7033 34.1373L36.7088 34.1356C37.3619 33.9432 37.7424 33.5365 37.9024 33.1503C38.0565 32.7781 38.0398 32.3628 37.7698 31.9611C37.7696 31.9608 37.7694 31.9606 37.7693 31.9603L35.4772 28.5664L35.4662 28.5501L35.4555 28.5336C35.0263 27.8704 34.6797 27.0424 34.4401 26.2635C34.2021 25.4899 34.0175 24.5959 34.0175 23.7837V18.6444C34.0175 14.0904 29.7494 9.97461 24.0493 9.97461C18.3293 9.97461 14.0811 14.0726 14.0811 18.6444V23.7837C14.0811 24.5966 13.8963 25.4919 13.6541 26.27C13.4106 27.052 13.0552 27.888 12.6067 28.5588L12.6016 28.5664ZM39.4285 30.8436C40.745 32.7997 39.6878 35.3427 37.2742 36.054C28.677 38.6148 19.4017 38.6148 10.8045 36.054C8.21137 35.2716 7.23397 32.942 8.65021 30.8436L10.9441 27.447C11.5625 26.5223 12.0811 24.8685 12.0811 23.7837V18.6444C12.0811 12.7582 17.4469 7.97461 24.0493 7.97461C30.6318 7.97461 36.0175 12.776 36.0175 18.6444V23.7837C36.0175 24.8685 36.5362 26.5223 37.1346 27.447L39.4285 30.8436Z" fill="#110202" />
            </svg>

            {harvestRequest > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 12,
                    }}
                >
                    {harvestRequest}
                </div>
            )}
        </div>
    );
}