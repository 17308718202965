import React from 'react';
import { ReactComponent as DataTableOval } from '../icons/DataTableOval.svg';
import './DataTable.css';

function DataTable({ props }) {
    const columns = Object.keys(props[0] || {});
    return (
        <table>
            <thead>
                <tr>
                    <th className="oval-th"></th>
                    <th>CW</th>
                    <th>Date</th>
                    <th>Predicted Available Ripe fruits (kg)</th>
                    <th>Harvested fruits / <span className="scheduled-harvest">Scheduled Harvest</span> (kg)</th>
                    <th>Harvest VS Predicted Available Ripe fruits (%)</th>
                </tr>
            </thead>
            <tbody>
                {props.map((row, index) => (
                    <tr key={index}>

                        <td className={`oval-td ${index === props.length - 1 ? 'oval-last' : index === props.length - 2 ? 'oval-penultimate' : ''}`}>
                            <DataTableOval />
                        </td>


                        {columns.map((column, colIndex) => (
                            <td key={column} className={colIndex === columns.length - 2 && index === props.length - 2 ? 'scheduled-last' : ''}>
                                {row[column] !== 'NaN' ?
                                    (typeof row[column] === 'number' ? (column === 'calendar_week' ? parseInt(row[column]) : Number(row[column]).toFixed(2)) : row[column])
                                    : ''}
                            </td>

                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default DataTable;
