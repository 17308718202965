import React, { useEffect, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CropSelector from "../Reusablecomponents/CropSelector";
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import MultiDatePicker from '../Reusablecomponents/MultiDatePicker';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import './ImageComparision.css';
import { fetchImagesForCalender1, fetchImagesForCalender2 } from '../api';




function ImageComparison() {
  const navigate = useNavigate();
  const location = useLocation();

  const { startDate, endDate, images, locationId: initialLocationId, cropId: initialCropId, siteId: initialSiteId, greenhouseId: initialGreenhouseId, compartmentId: initialCompartmentId } = location.state || {
    startDate: null,
    endDate: null,
    images: [],
    cropId: null,
    locationId: null,
    siteId: null,
    greenhouseId: null,
    compartmentId: null,
  };

  const [locationId, setLocationId] = useState(initialLocationId);
  const [cropId, setCropId] = useState(initialCropId);
  const [currentImages, setCurrentImages] = useState(images);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [siteId, setSiteId] = useState(initialSiteId);
  const [greenhouseId, setGreenhouseId] = useState(initialGreenhouseId);
  const [compartmentId, setCompartmentId] = useState(initialCompartmentId);

  console.log(cropId)

  useEffect(() => {
    const fetchImagesForCalendar1 = async () => {
      try {
        setLoading1(true);
        let calender1Images;
        if (locationId) {
          calender1Images = await fetchImagesForCalender1(selectedStartDate, cropId, locationId);
        } else {
          calender1Images = await fetchImagesForCalender1(selectedStartDate, cropId);
        }

        const sortImagesByTimestamp = (images) => {
          return images.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        };

        const sortedCalender1Images = sortImagesByTimestamp(calender1Images);

        setCurrentImages(prevImages => [
          sortedCalender1Images.length > 0 ? {
            url: sortedCalender1Images[0].image_url,
            timestamp: sortedCalender1Images[0].timestamp,
            uuid: sortedCalender1Images[0].uuid
          } : null,
          prevImages[1]
        ]);
      } catch (error) {
        console.error('Error fetching images for Calendar 1:', error);
        setCurrentImages(prevImages => [null, prevImages[1]]);
      } finally {
        setLoading1(false);
      }
    };

    if (selectedStartDate && cropId) {
      fetchImagesForCalendar1();
    }
  }, [selectedStartDate, cropId, locationId]);

  useEffect(() => {
    const fetchImagesForCalendar2 = async () => {
      try {
        setLoading2(true);
        let calender2Images;
        if (locationId) {
          calender2Images = await fetchImagesForCalender2(selectedEndDate, cropId, locationId);
        } else {
          calender2Images = await fetchImagesForCalender2(selectedEndDate, cropId);
        }

        const sortImagesByTimestamp = (images) => {
          return images.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        };

        const sortedCalender2Images = sortImagesByTimestamp(calender2Images);

        setCurrentImages(prevImages => [
          prevImages[0],
          sortedCalender2Images.length > 0 ? {
            url: sortedCalender2Images[0].image_url,
            timestamp: sortedCalender2Images[0].timestamp,
            uuid: sortedCalender2Images[0].uuid
          } : null
        ]);
      } catch (error) {
        console.error('Error fetching images for Calendar 2:', error);
        setCurrentImages(prevImages => [prevImages[0], null]);
      } finally {
        setLoading2(false);
      }
    };

    if (selectedEndDate && cropId) {
      fetchImagesForCalendar2();
    }
  }, [selectedEndDate, cropId, locationId]);


  const handleStartDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelectedStartDate(formattedDate);
  };


  const handleEndDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelectedEndDate(formattedDate);
  };


  const handleBackToGallery = () => {
    navigate('/Image-Tracker');
  };


  const handleNavigation = async (direction, calendar) => {
    const sortImagesByTimestamp = (images) => {
      return images.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    };

    if (calendar === 'cal1') {
      setLoading1(true);
      const newStartDate = dayjs(selectedStartDate).add(direction, 'day').format('YYYY-MM-DD');
      setSelectedStartDate(newStartDate);

      try {
        let updatedImages = await fetchImagesForCalender1(newStartDate, cropId, locationId);
        updatedImages = sortImagesByTimestamp(updatedImages);
        setCurrentImages(prevImages => [
          updatedImages.length > 0 ? {
            url: updatedImages[0].image_url,
            timestamp: updatedImages[0].timestamp,
            uuid: updatedImages[0].uuid
          } : null,
          prevImages[1]
        ]);
      } catch (error) {
        console.error('Error fetching images for new start date:', error);
      } finally {
        setLoading1(false);
      }
    }
    else if (calendar === 'cal2') {
      setLoading2(true);
      const newEndDate = dayjs(selectedEndDate).add(direction, 'day').format('YYYY-MM-DD');
      setSelectedEndDate(newEndDate);

      try {
        let updatedImages = await fetchImagesForCalender2(newEndDate, cropId, locationId);
        updatedImages = sortImagesByTimestamp(updatedImages);
        setCurrentImages(prevImages => [
          prevImages[0],
          updatedImages.length > 0 ? {
            url: updatedImages[0].image_url,
            timestamp: updatedImages[0].timestamp,
            uuid: updatedImages[0].uuid
          } : null
        ]);
      } catch (error) {
        console.error('Error fetching images for new end date:', error);
      } finally {
        setLoading2(false);
      }
    }
  };

  const imageOne = currentImages[0];
  const imageTwo = currentImages[1];


  return (
    <Box display="flex" flexDirection="column" position="fixed" top="0" left="0" width="100vw" height="100vh" zIndex="1300" bgcolor="#717171">
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" p={2} bgcolor="#F9F9F9">
        <CropSelector
          setCropId={setCropId}
          onLocationChange={setLocationId}
          onSiteChange={setSiteId}
          onGreenhouseChange={setGreenhouseId}
          setCompartmentId={setCompartmentId}
          showLocation={true}
          showCW={false}
          initialLocationId={locationId}
          initialCropId={cropId}
          initialSiteId={siteId}
          initialGreenhouseId={greenhouseId}
          initialCompartmentId={compartmentId}
          isImageTracker={true}
        />
        <IconButton onClick={handleBackToGallery} sx={{
          padding: '5px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Box className="flex-container">
        <Box className="styled-box">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MultiDatePicker
              startDate={dayjs(selectedStartDate)}
              endDate={dayjs(selectedEndDate)}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              showLabel={false}

            />
          </LocalizationProvider>
        </Box>
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" position="relative">

          <>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center" position="relative" width='calc(100vw - 200px)' maxWidth='1440px'>
              <IconButton
                sx={{
                  position: 'absolute',
                  left: '1%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 10,
                  marginLeft: '0px',
                }}
                onClick={() => handleNavigation(-1, 'cal1')}
                disabled={loading1}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                sx={{
                  position: 'absolute',
                  left: '6%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 10,
                }}
                onClick={() => handleNavigation(1, 'cal1')}
                disabled={loading1}
              >
                <ArrowForwardIosIcon />
              </IconButton>

              {loading1 && (
                <Box position="absolute" left="30%" top="50%" zIndex="1400"
                  sx={{
                    transform: 'translate(50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1400
                  }}>
                  <CircularProgress size={40} style={{ color: '#05668D' }} />
                </Box>
              )}
              {imageOne && imageTwo && cropId ? (
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage
                      src={imageOne.url}
                      alt={`Image from ${dayjs(imageOne.timestamp).format('YYYY-MM-DD HH:mm')}`}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={imageTwo.url}
                      alt={`Image from ${dayjs(imageTwo.timestamp).format('YYYY-MM-DD HH:mm')}`}
                    />
                  }
                  style={{ height: 'calc(100vh - 75px)', maxWidth: 'calc(100vw - 320px)', objectFit: 'contain' }}
                />
              ) : (
                <Box textAlign="center">
                  {!cropId && (
                    <Typography variant="h6" color="white">
                      No image available for the selected crop.
                    </Typography>
                  )}
                  {!imageOne && (
                    <Typography variant="h6" color="white">
                      No image available for the first selected date.
                    </Typography>
                  )}
                  {!imageTwo && (
                    <Typography variant="h6" color="white">
                      No image available for the second selected date.
                    </Typography>
                  )}
                </Box>
              )}
              <IconButton
                sx={{
                  position: 'absolute',
                  right: '5%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 10,
                  marginRight: '14px',
                }}
                onClick={() => handleNavigation(-1, 'cal2')}
                disabled={loading2}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: '0%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  marginRight: '20px',
                }}
                onClick={() => handleNavigation(1, 'cal2')}
                disabled={loading2}
              >
                <ArrowForwardIosIcon />
              </IconButton>

              {loading2 && (
                <Box position="absolute" right="30%" top="50%" sx={{
                  transform: 'translate(50%, -50%)',
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1400
                }}>
                  <CircularProgress size={40} style={{ color: '#05668D' }} />
                </Box>
              )}
            </Box>
          </>

        </Box>
      </Box>
    </Box>
  );
}


export default ImageComparison;
