import { React } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import tomatoIcon_red from '../icons/tomatoIcon_red.png';
import tomato_basket_Icon from '../icons/tomato_basket_icon.png';
import './BarChart.css';

function makeRenderTooltipContent(sensorData) {
    return (props) => {
        let payload = [];
        const info = props.payload.length ? props.payload[0].payload : null;

        if (info && info.harvestable_fruits !== undefined && info.harvestEventVolume !== undefined) {
            const totalBeforeHarvest = Number(info.harvestable_fruits) + Number(info.harvestEventVolume);
            payload.push({
                name: 'Harvestable fruits (kg) ',
                value: `${totalBeforeHarvest.toFixed(2)}`,
            });
        }

        if (info && info.harvestEventVolume !== undefined) {
            const harvestEventVolume = Number(info.harvestEventVolume).toFixed(2);
            payload.push({
                name: 'Harvested fruits (kg)',
                value: `${harvestEventVolume}`,
            });
        }

        if (info && info.harvestable_fruits !== undefined) {
            payload.push({
                name: 'Remaining after harvest (kg)',
                value: `${info.harvestable_fruits}`,
            });

        }
        const weekNumber = info?.calendar_week
        const selectedWeekInfo = sensorData.find(w => w.calendar_week === weekNumber)
        if (selectedWeekInfo) {
            payload = [
                ...payload,
                ...Object.entries(selectedWeekInfo)
                    .filter(([key]) => key !== "calendar_week" && key !== "date_time")
                    .map(([key, value]) => ({ name: key, value })),
            ];

        }

        payload = payload.filter((item) => item.name !== 'harvestable_fruits' && item.name !== 'harvestEventVolume');
        const nameMappings = {
            "light_J": "Light intensity (J)",
            "temperature_day": "Day temp (°C)",
            "temperature_night": "Night temp (°C)",
            "CO2_ppm": "CO2 (ppm)"
        };
        payload = payload.map(item => ({
            ...item,
            name: nameMappings[item.name] || item.name
        }));
        payload = payload.map(item => {
            if (typeof item.value === 'number') {
                item.value = parseFloat(item.value).toFixed(2);
            }
            return item;
        });
        if (info && info.previousPredictionValue) {
            payload = [
                ...payload,
                {
                    name: 'Previous Prediction Value',
                    value: `${info.previousPredictionValue}kg`,
                },
            ];
        }

        return <DefaultTooltipContent {...props} payload={payload} />;
    };
}


function BarChartComponent({ props, sensorData, isHarvestChanged, historyLength, previousPredictionValues }) {
    const modifiedData = props.map((item, index) => {
        const modifiedItem = { ...item };
        modifiedItem.harvestEventVolume = modifiedItem.harvestEventVolume ? Number(modifiedItem.harvestEventVolume) : 0;
        modifiedItem.harvestable_fruits = modifiedItem.harvestable_fruits !== undefined ? modifiedItem.harvestable_fruits : 0;
        modifiedItem.harvestable_fruits -= modifiedItem.harvestEventVolume;
        return modifiedItem;
    });
    const maxValue = props && props.length > 0
        ? Math.max(...props.map(item =>
            item.harvestable_fruits != null && !isNaN(item.harvestable_fruits)
                ? item.harvestable_fruits.toFixed(0)
                : 0))
        : 0;

    const thisWeekHarvest = previousPredictionValues && previousPredictionValues[0];
    const nextWeekHarvest = previousPredictionValues && previousPredictionValues[1];
    if (modifiedData.length >= historyLength + 2 && previousPredictionValues) {
        modifiedData[historyLength].previousPredictionValue = thisWeekHarvest;
        modifiedData[historyLength + 1].previousPredictionValue = nextWeekHarvest;
    }

    return (
        <div className="chart-container">
            <ResponsiveContainer width="100%" height={500}>
                <BarChart
                    data={modifiedData.map(item => ({ ...item, harvestable_fruits: item.harvestable_fruits.toFixed(2) }))}
                    margin={{
                        top: 5,
                        right: 170,
                        left: 15,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="calendar_week" label={{ value: 'Calendar Week ', position: 'insideBottomRight', dy: 10 }} axisLine={false} />
                    <YAxis yAxisId="left" orientation="left" label={{ value: 'Harvestable Fruits (kg)', angle: -90, position: 'insideLeft', dy: 20, dx: -10 }} domain={[1000, maxValue * 1.2]} />
                    <ReferenceLine purpose='fake x axis' yAxisId='left' y={0} stroke='#666666' label={{
                        value: `0`,
                        position: 'left',

                    }} />
                    <Tooltip
                        labelFormatter={(value, fruits) => {
                            const item = props.find((obj) => obj.calendar_week === value);
                            const harvestableFruits = item ? item.harvestable_fruits : '';
                            const date = item ? item.date : '';
                            const calendarWeek = `Calendar Week ${value}`;
                            return (
                                <span>
                                    <span style={{ display: 'block', lineHeight: '1.4', marginBottom: '7px', fontWeight: '900', fontSize: '14px' }}>{`${calendarWeek}`}</span>
                                </span>
                            );
                        }}
                        cursor={{ fill: 'transparent' }}
                        content={makeRenderTooltipContent(sensorData)}
                        contentStyle={{ backgroundColor: 'white', border: '1px solid #FFA737', padding: '10px', borderRadius: '5px', color: 'black', fontSize: '12px', fontWeight: '600' }}
                    />
                    <Legend
                        verticalAlign="top"
                        height={55}
                        width={900}
                        align="left"
                        iconType="circle"
                        iconSize={10}
                        formatter={(value) => <span style={{ color: '#110202', fontSize: '12px', fontWeight: '900' }}>{value}</span>}
                        payload={[
                            { dataKey: 'Past prediction available ripe fruits', value: 'Past prediction available ripe fruits', color: '#B5B5B5' },
                            { dataKey: 'Past harvested fruits', value: 'Past harvested fruits', color: '#717171' },
                            { dataKey: 'Predicted available ripe fruits', value: 'Predicted available ripe fruits', color: '#69A3BB' },
                            { dataKey: 'Scheduled harvest', value: 'Scheduled harvest', color: '#05668D' },
                            { dataKey: 'Remaining after harvest', value: 'Remaining after harvest', color: '#B4D1DD' },
                            { value: <span> <img src={tomatoIcon_red} alt="Tomato Icon" width="16" height="16" style={{ marginLeft: '-19px' }} /> <span> Scheduled harvest plan</span></span>, color: '#ffffff00' },
                            { value: <span><img src={tomato_basket_Icon} alt="Tomato basket Icon" width="16" height="16" style={{ margin: '0 4px 0 -15px' }} /><span>Completed harvest plan</span></span>, color: '#ffffff00' },
                        ]}
                        style={{ marginBottom: '30px' }}
                    />
                    <Bar yAxisId="left" dataKey="harvestable_fruits" stackId="stacked" barSize={50}>
                        {modifiedData.map((item, index) => {
                            let color;
                            if ((index >= historyLength + 1)) {
                                color = '#69A3BB';
                            } else if (index >= historyLength) {
                                color = '#B4D1DD';
                            } else {
                                color = '#B5B5B5';
                            }

                            return <Cell key={`cell-${index}`} fill={color} />;
                        })}
                    </Bar>
                    <Bar yAxisId="left" dataKey="harvestEventVolume" stackId="stacked" barSize={50}>
                        {modifiedData.map((item, index) => {
                            let color;
                            if (index >= historyLength) {
                                color = '#05668D';
                            } else {
                                color = '#717171';
                            }
                            return <Cell key={`cell-harvestValue-${index}`} fill={color} />;
                        })}
                        <LabelList
                            dataKey="harvestEventVolume"
                            position="top"
                            content={(props) => {

                                const { x, y, height, value, index } = props;
                                const currentItem = modifiedData[index];
                                const harvestableFruits = currentItem.harvestable_fruits.toFixed(2);
                                let modifiedValue = value;
                                if (typeof value === 'undefined') {
                                    modifiedValue = 0;
                                }
                                const currentValue = Number(modifiedValue).toFixed(2);
                                const totalValue = (Number(currentValue) + Number(harvestableFruits)).toFixed(2);
                                const imageY = y + height / 2 - 12;
                                return (
                                    <g>
                                        <text x={x - 5} y={y - 5} fill="#B5B5B5">{`${totalValue}`}</text>

                                        {(index < historyLength - 1) && (
                                            <image x={x + 12} y={imageY} width={24} height={24} xlinkHref={tomato_basket_Icon} />
                                        )}
                                        {(index >= historyLength - 1 && index === historyLength - 1) && (
                                            <image x={x + 12} y={imageY} width={24} height={24} xlinkHref={tomato_basket_Icon} />
                                        )}
                                        {(index >= historyLength && index < historyLength + 1) && (
                                            <image x={x + 12} y={imageY} width={24} height={24} xlinkHref={tomatoIcon_red} />
                                        )}
                                    </g>
                                );
                            }}
                        />
                    </Bar>
                </BarChart >
            </ResponsiveContainer >
        </div >
    );
}

export default BarChartComponent;
