import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Popover, Stack, Typography } from '@mui/material';
import CustomTextField from '../Reusablecomponents/CustomTextField';
import './IndoorClimate.css';
import CustomButton from '../Reusablecomponents/Custombutton';
import CircularProgress from '@mui/material/CircularProgress';
import climate from '../icons/Climate.png';
import TomatoOutlineIcon from '../icons/tomato_icon_outline.png';
import { fetchPrediction } from "../api";
import CustomButtonStack from '../Reusablecomponents/CustomButtonStack';


function IndoorClimate({ setYieldPrediction, yieldPrediction, sensorData, setSensorData, getHarvestValues, cropId, baseHarvestData, setPreviousPredictionValues, futureHarvestData, noData }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorHarvest, setAnchorHarvest] = useState(null);
    const [light, setLight] = useState(sensorData && sensorData.length > 0 ? sensorData[sensorData.length - 1].light_J || '' : '');
    const [t_Day, setT_Day] = useState(sensorData && sensorData.length > 0 ? sensorData[sensorData.length - 1].temperature_day || '' : '');
    const [t_Night, setT_Night] = useState(sensorData && sensorData.length > 0 ? sensorData[sensorData.length - 1].temperature_night || '' : '');
    const [co2, setCo2] = useState(sensorData && sensorData.length > 0 ? sensorData[sensorData.length - 1].CO2_ppm || '' : '');

    useEffect(() => {
        if (sensorData && sensorData.length > 0) {
            const lastData = sensorData[sensorData.length - 1];
            setLight(lastData.light_J >= 0 ? parseFloat(lastData.light_J).toFixed(2) : '');
            setT_Day(lastData.temperature_day >= 0 ? parseFloat(lastData.temperature_day).toFixed(2) : '');
            setT_Night(lastData.temperature_night >= 0 ? parseFloat(lastData.temperature_night).toFixed(2) : '');
            setCo2(lastData.CO2_ppm >= 0 ? parseFloat(lastData.CO2_ppm).toFixed(2) : '');
        }
    }, [sensorData]);
    const defaultLight = sensorData && sensorData.length > 0 ? parseFloat(sensorData[sensorData.length - 1].light_J || '').toFixed(2) : '';
    const defaultT_Day = sensorData && sensorData.length > 0 ? parseFloat(sensorData[sensorData.length - 1].temperature_day || '').toFixed(2) : '';
    const defaultT_Night = sensorData && sensorData.length > 0 ? parseFloat(sensorData[sensorData.length - 1].temperature_night || '').toFixed(2) : '';
    const defaultCo2 = sensorData && sensorData.length > 0 ? parseFloat(sensorData[sensorData.length - 1].CO2_ppm || '').toFixed(2) : '';

    const [fetchError, setFetchError] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isClimatePopoverOpen, setIsClimatePopoverOpen] = useState(false);
    const [isHarvestPopoverOpen, setIsHarvestPopoverOpen] = useState(false);
    const [isClimateButtonHovered, setIsClimateButtonHovered] = useState(false);
    const [isHarvestButtonHovered, setIsHarvestButtonHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(true)
    const [isFirstTimeF, setIsFirstTimeF] = useState(true)

    const [currentHarvest, setCurrentHarvest] = useState();
    const [futureHarvest, setFutureHarvest] = useState();
    const [isSave, setIsSave] = useState(false);

    const [currentHarvestableFruits, setCurrentHarvestableFruits] = useState(0);
    const [futureHarvestableFruits, setFutureHarvestableFruits] = useState(0);
    const [currentCW, setCurrentCW] = useState(0);
    const [futureCW, setFutureCW] = useState(0);
    useEffect(() => {
        setIsSave(true);
        setCurrentHarvestableFruits(0);
        setFutureHarvestableFruits(0);
        setCurrentHarvest(undefined);
        setFutureHarvest(undefined);
        setYieldPrediction([]);
    }, [cropId])

    useEffect(() => {
        if (!isSave || !cropId || !baseHarvestData) return;

        const fetchData = async () => {
            setIsLoading(true);
            if (yieldPrediction && yieldPrediction.length > 0) {
                setPreviousPredictionValues([yieldPrediction[0].yield_prediction, yieldPrediction[1].yield_prediction])
            }

            let apiRequest;
            const futureHarvestWeight = futureHarvestData !== undefined ? futureHarvestData : baseHarvestData.harvested_weight_future;

            if (light > 0 && t_Day > 0 && t_Night > 0 && co2 > 0) {
                apiRequest = fetchPrediction(cropId, currentHarvest || baseHarvestData.harvested_weight, futureHarvest || futureHarvestWeight, light, t_Day, t_Night, co2);
            } else {
                apiRequest = fetchPrediction(cropId, currentHarvest || baseHarvestData.harvested_weight, futureHarvest || futureHarvestWeight);
            }

            try {
                const data = await apiRequest;
                setYieldPrediction(data);
                const mergedData = [baseHarvestData, ...data];
                setCurrentHarvestableFruits(Math.floor(mergedData[0].prognosis));
                setFutureHarvestableFruits(Math.floor(mergedData[1].yield_prediction));
                setCurrentCW(Math.floor(mergedData[0].calendar_week));
                setFutureCW(Math.floor(mergedData[1].calendar_week));
                if (!currentHarvest && !futureHarvest) {
                    setCurrentHarvest(baseHarvestData.harvested_weight);
                    setFutureHarvest(baseHarvestData.harvested_weight_future);
                }
            } catch (error) {
                console.error(error);
                setFetchError(true);
            } finally {
                setIsLoading(false);
                setIsSave(false);
            }
        };

        fetchData(cropId);
    }, [isSave, currentHarvest, futureHarvest, cropId, baseHarvestData, sensorData, light, t_Day, t_Night, co2]);



    useEffect(() => {
        getHarvestValues(currentHarvest, futureHarvest, false);
    }, [currentHarvest, futureHarvest]);

    const handleClimateMouseEnter = () => {
        setIsClimateButtonHovered(true);
        setIsClimatePopoverOpen(true);
    };

    const handleClimateMouseLeave = () => {
        setIsClimateButtonHovered(false);
        setIsClimatePopoverOpen(false);
    };


    const handleHarvestMouseEnter = () => {
        setIsHarvestButtonHovered(true);
        setIsHarvestPopoverOpen(true);
    };

    const handleHarvestMouseLeave = () => {
        setIsHarvestButtonHovered(false);
        setIsHarvestPopoverOpen(false);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsButtonClicked(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsButtonClicked(false);
    };

    const handleSave = async () => {
        setIsSave(true);
        await fetchPrediction(
            cropId,
            currentHarvest || baseHarvestData.harvested_weight,
            futureHarvest || baseHarvestData.harvested_weight,
            light,
            t_Day,
            t_Night,
            co2
        );
        handleClose();


        const lastObjectIndex = sensorData.length - 1;
        if (lastObjectIndex >= 0) {
            sensorData[lastObjectIndex] = {
                ...sensorData[lastObjectIndex],
                light_J: light,
                temperature_day: t_Day,
                temperature_night: t_Night,
                CO2_ppm: co2,
            };
        }

        // Set the updated sensorData
        setSensorData(sensorData);
    };

    const handleHarvestPopoverOpen = (event) => {
        setAnchorHarvest(event.currentTarget);
        setIsClimatePopoverOpen(true);
    };

    const handleHarvestDone = () => {
        handleCloseHarvestPopover();
        getHarvestValues(currentHarvest, futureHarvest, true)
        setIsSave(true)
    };


    const handleCloseHarvestPopover = () => {
        setAnchorHarvest(null);
    };
    const handleChange = (event) => {
        const value = event.target.value.trim();
        if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
            setLight(value);
        }
    };

    const handleChangeT_Day = (event) => {
        const value = event.target.value.trim();
        if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
            setT_Day(value);
        }
    };

    const handleChangeT_Night = (event) => {
        const value = event.target.value.trim();
        if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
            setT_Night(value);
        }
    };

    const handleChangeCo2 = (event) => {
        const value = event.target.value.trim();
        if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
            setCo2(value);
        }
    };



    const handleCurrentScheduledHarvestChange = (event) => {
        setIsFirstTime(false);
        const value = event.target.value.trim();
        if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
            setCurrentHarvest(value);
        }
    };

    const handleFutureScheduledHarvestChange = (event) => {
        setIsFirstTimeF(false);
        const value = event.target.value.trim();
        if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
            setFutureHarvest(value);

        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'indoor-climate-popover' : undefined;
    const openHarvestPopover = Boolean(anchorHarvest);
    const saveButtonRef = useRef(null);
    return (
        <div>
            {isLoading && !noData ? (
                <div className="loading-overlay">
                    <CircularProgress size={40} style={{ color: '#05668D' }} />
                </div>
            ) : (
                <Stack direction="row" spacing={3} alignItems="center">
                    <CustomButton
                        sx={{ color: "white", }}
                        onClick={handleClick}
                        onMouseEnter={handleClimateMouseEnter}
                        onMouseLeave={handleClimateMouseLeave}
                        backgroundColor="#6B6B6B"
                        hoverBackgroundColor="#05668D"
                        activeBackgroundColor="#05668D"
                        icon={climate}
                        label="Simulate climate Change"
                        disabled={noData}
                    />
                    <Popover
                        className="popover_class"
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            style: {
                                borderRadius: '6px',
                                marginTop: '10px',
                            },
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                saveButtonRef.current.click();
                                handleClose();
                            }
                        }}
                    >
                        {/* Popover content */}
                        <Box sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                <Box sx={{ my: 1 }}>
                                    <Typography variant="h6" sx={{ color: '#05668D', fontWeight: '600' }}>
                                        Simulate indoor climate
                                    </Typography>
                                    <Divider />
                                </Box>
                                <CustomTextField
                                    label={`Light (800-20000 Joule, default: ${defaultLight})`}
                                    value={light || ''}
                                    onChange={handleChange}
                                />
                                <CustomTextField
                                    label={`Day_Temp (18-27 °C, default: ${defaultT_Day})`}
                                    value={t_Day || ''}
                                    onChange={handleChangeT_Day}
                                />
                                <CustomTextField
                                    label={`Night_Temp (14-18 °C, default: ${defaultT_Night})`}
                                    value={t_Night || ''}
                                    onChange={handleChangeT_Night}
                                />
                                <CustomTextField
                                    label={`CO2 (300 - 850 ppm, default: ${defaultCo2})`}
                                    value={co2 || ''}
                                    onChange={handleChangeCo2}
                                />
                                <CustomButtonStack
                                    onCancelClick={handleClose}
                                    onButtonClick={handleSave}
                                    isDisabled={!light}
                                    cancelLabel="Cancel"
                                    sendLabel="Done"
                                    height="32px"
                                    width="82px"
                                    saveButtonRef={saveButtonRef}
                                />
                            </Stack>
                        </Box>
                    </Popover>

                    <CustomButton
                        sx={{ color: "white", }}
                        onClick={handleHarvestPopoverOpen}
                        onMouseEnter={handleHarvestMouseEnter}
                        onMouseLeave={handleHarvestMouseLeave}
                        backgroundColor="#6B6B6B"
                        hoverBackgroundColor="#05668D"
                        activeBackgroundColor="#05668D"
                        icon={TomatoOutlineIcon}
                        label="Add harvest plan"
                        disabled={noData}
                    />
                    <Popover
                        id="harvest-popover"
                        open={openHarvestPopover}
                        anchorEl={anchorHarvest}
                        onClose={handleCloseHarvestPopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            style: {
                                borderRadius: '6px',
                                marginTop: '10px',
                            },
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                saveButtonRef.current.click();
                                handleClose();
                            }
                        }}
                    >
                        {/* Harvest popover content */}
                        <Box sx={{ p: 1 }}>
                            <Stack spacing={2}>
                                <Box sx={{ my: 1 }}>
                                    <Typography variant="h6" sx={{ color: '#05668D', fontWeight: '600' }}>
                                        Add harvest plan
                                    </Typography>
                                    <Divider />
                                </Box>
                                <CustomTextField
                                    label={`CW${futureCW}, (Max ${futureHarvestableFruits.toFixed(2)} kg)`}
                                    value={futureHarvest || ''}
                                    onChange={handleFutureScheduledHarvestChange}
                                />
                                <CustomButtonStack
                                    onCancelClick={handleCloseHarvestPopover}
                                    onButtonClick={handleHarvestDone}
                                    saveButtonRef={saveButtonRef}
                                    isDisabled={!currentHarvest}
                                    cancelLabel="Cancel"
                                    sendLabel="Done"
                                    height="32px"
                                    width="82px"
                                />
                            </Stack>
                        </Box>
                    </Popover>
                </Stack>
            )}

        </div>
    );
}

export default IndoorClimate;