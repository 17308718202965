import React, { useEffect, useState } from "react";
import "./CurrentDate.css";

const CurrentDate = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const timerId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    const options = { weekday: 'short', day: 'numeric', month: 'short' };
    const displayTodayDate = currentDate.toLocaleDateString(undefined, options)
        .split(' ').map((part, index) => {
            if (index === 1) return currentDate.getDate();
            if (index === 2) return currentDate.toLocaleDateString(undefined, { month: 'short' });
            return part;
        }).join(' ');
    const displayTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    return (
        <div className="date-container">
            {displayTodayDate}, {displayTime}
        </div>
    );
};

export default CurrentDate;
