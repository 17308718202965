import { Auth } from 'aws-amplify';

async function getToken() {
    try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        return idToken;
    } catch (error) {
        console.error('Error getting Cognito token:', error);
        return null;
    }
}

async function getUserID() {
    try {
        const session = await Auth.currentSession();
        return session.getIdToken().decodePayload().sub;
    } catch (error) {
        throw new Error(`Error getting Cognito user ID: ${error}`);
    }
}

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.error('Error signing out:', error);
    }
}

export default {
    getToken,
    getUserID,
    signOut,
};
