export const formatRequestedDate = (dateString, includeTime = false, includeCW = true, includeYear = false, includeWeekday = false) => {
    const date = new Date(dateString);
    const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date).slice(0, 3);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date).slice(0, 3);
    const year = date.getFullYear();
    const weekNumber = getWeekNumber(date);
    const time = includeTime ? date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }) : '';
    return `${includeCW ? 'CW' + weekNumber + ',' : ''} ${includeWeekday ? weekday + ',' : ''} ${day} ${month} ${includeYear ? year : ''} ${includeTime ? time : ''}`;
};

const getWeekNumber = (date) => {
    const target = new Date(date);
    const dayNr = (date.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNr + 3);
    const firstThursday = new Date(target.getFullYear(), 0, 4);
    const weekNumber = Math.ceil((((target - firstThursday) / 86400000) + firstThursday.getDay() + 1) / 7);
    return weekNumber;
};

