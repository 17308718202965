import React, { useState } from 'react';
import { Typography, Box, ThemeProvider, createTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import updateLocale from "dayjs/plugin/updateLocale";
import './CustomDateField.css';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        caption: {
          '&.MuiDayCalendar-weekNumberLabel': {
            color: '#02C39A',
          },
        },
      },
    },
  },
});

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1
});

function MultiDatePicker({ startDate, endDate, onStartDateChange, onEndDateChange, showLabel = true }) {
  const [openPicker, setOpenPicker] = useState(null);

  const handleStartDateOpen = () => {
    setOpenPicker('startDate');
  };

  const handleEndDateOpen = () => {
    setOpenPicker('endDate');
  };

  const handlePickerClose = () => {
    setOpenPicker(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}
        localeText={{
          calendarWeekNumberHeaderText: 'CW',
          calendarWeekNumberText: (weekNumber) => `${weekNumber}.`,
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8.5rem' }}>
          {showLabel && (
            <Typography variant="body1" sx={{ marginRight: 2 }}>
              Date:
            </Typography>
          )}
          <Box sx={{ display: 'flex', border: '1px solid #B5B5B5', borderRadius: '7px', height: '30px', alignItems: 'center' }}>
            <DatePicker
              displayWeekNumber={true}
              className='date'
              value={startDate}
              onChange={onStartDateChange}
              onOpen={handleStartDateOpen}
              onClose={handlePickerClose}
              maxDate={dayjs()}
              open={openPicker === 'startDate'}
              format={`CW${startDate ? dayjs(startDate).week() : ''}, DD MMM YYYY`}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                textField: {
                  className: 'myDatePicker',
                  InputProps: {
                    readOnly: true,
                    classes: {
                      notchedOutline: 'MuiOutlinedInput-notchedOutline',
                    },
                    sx: {
                      '.MuiInputBase-input': {
                        marginLeft: '-9px'
                      },
                    },
                  },
                },
                popper: {
                  sx: {
                    ".MuiPaper-root": {
                      border: "2px solid #B5B5B5",
                      borderRadius: "7px",
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: '#05668D',
                      },
                      "& .MuiPickersDay-root:hover": {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                      },
                      '& .MuiDayCalendar-weekNumber': {
                        color: '#02C39A',
                      },
                    },
                  },
                },
              }}
            />
            <Box sx={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: '#717171',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

            }}>
              <Typography sx={{ color: '#F9F9F9', fontSize: '10px', fontWeight: 700 }}>VS</Typography>
            </Box>
            <DatePicker
              displayWeekNumber={true}
              className='date'
              value={endDate}
              onChange={onEndDateChange}
              onOpen={handleEndDateOpen}
              onClose={handlePickerClose}
              maxDate={dayjs()}
              open={openPicker === 'endDate'}
              format={`CW${endDate ? dayjs(endDate).week() : ''}, DD MMM YYYY`}
              slotProps={{
                inputAdornment: {
                  position: 'start'
                },
                textField: {
                  className: 'myDatePicker',
                  InputProps: {
                    readOnly: true,
                    classes: {
                      notchedOutline: 'MuiOutlinedInput-notchedOutline',
                    },
                    sx: {
                      '.MuiInputBase-input': {
                        marginLeft: '-9px'
                      },
                    },
                  },
                },
                popper: {
                  sx: {
                    ".MuiPaper-root": {
                      border: "2px solid #B5B5B5",
                      borderRadius: "7px",
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: '#05668D',
                      },
                      "& .MuiPickersDay-root:hover": {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                      },
                      '& .MuiDayCalendar-weekNumber': {
                        color: '#02C39A',
                      },
                    },
                  },
                },
              }}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default MultiDatePicker;
