import React from 'react';
import './Card.css';

function Card(props) {
    const { header, height } = props;
    const cardStyle = {
        height: `${height}px`,
    };

    return (
        <div className='cardCheckBox' style={cardStyle}>
            <div className='cardCheckBox_header'>{header}</div>
            <div className='cardCheckBox_body'>
                {props.children}
            </div>
        </div >
    );
}

export default Card;
